import { get, put, Delete, post } from "../axios";

// 供应商-分组列表
export const list = (params) =>
  get(
    `/goods/web/goodsClassify/supplier/list`,
    params
  );
// 供应商分类列表
export const allList = (params) =>
  get(
    `/goods/web/goodsCategory/supplier/list`,
    params
  );
// 是否显示
export const show = (params) =>
  get(
    `/goods/web/goodsClassify/state`,
    params
  );
// 供应商-新增
export const add = (params) =>
  post(
    `/goods/web/goodsClassify/add`,
    params
  );
// 供应商-编辑
export const update = (params) =>
  put(
    `/goods/web/goodsClassify/update`,
    params
  );
// 供应商-删除
export const deletes = (params) =>
  Delete(`/goods/web/goodsClassify/delete/${params}`);
